<script>
import {mapGetters} from "vuex";

export default {
  name: "Field",
  props: {
    schema: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    multipleForms: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
    highlight: {
      type: Boolean,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters("Task", {
      fields: "getFields",
    }),
    model: {
      get() {
        if (this.disabled) return null
        if (this.multipleForms) {
          const val = this.fields[this.index].value
          return val ? val[this.multipleFormsIndex] : null
        }
        else
          return this.fields[this.index].value
      },
      set(payload) {
        this.$store.commit("Task/SET_FIELDS", {
          index: this.index,
          multipleForms: this.multipleForms,
          multipleFormsIndex: this.multipleFormsIndex,
          field: payload
        })
      },
    },
    isYearPicker() {
      switch (this.schema.type) {
        case "date":
          return false
        case "year":
          return true
        default:
          return undefined
      }
    },
    field() {
      switch (this.schema.type) {
      case "button_select":
        return () => import("@/components/Inputs/InputSelectBtn");
      case "text":
        return () => import("@/components/Inputs/InputText");
      case "date":
        return () => import("@/components/Inputs/InputDate");
      case "year":
        return () => import("@/components/Inputs/InputDate");
      case "number":
        return () => import("@/components/Inputs/InputNumber");
      case "select":
        return () => import("@/components/Inputs/InputSelect");
      case "phone":
        return () => import("@/components/Inputs/InputNumber");
      case "email":
        return () => import("@/components/Inputs/InputText");
      case "slider":
        return () => import("@/components/Inputs/InputSlider");
      case "link":
        return () => import("@/components/Actions/Link");
      case "test_select":
        return () => import("@/components/Inputs/InputSelectRadio");
      case "picture":
        return () => import("@/components/Inputs/InputSelectBtn");
      default:
        return () => import("@/components/Inputs/InputSelectBtn");
      }
    },
    internalSchema() {
      const schema = {...this.schema, savedValue: ""}
      if ("savedValue" in schema) {
        this.$set(schema, 'savedValue', null)
      }
      return schema
    },
  },
  methods: {
    onEnter() {
      this.$emit("enter");
    }
  }
}
</script>

<template>
  <component
    :is="field"
    v-model="model"
    v-bind="internalSchema"
    :disabled="disabled"
    :highlight="highlight"
    :year-only="isYearPicker"
    :multiple-forms="multipleForms"
    :multiple-forms-index="multipleFormsIndex"
    @enter="onEnter"
  />
</template>